import { calculateDiscount } from '@redant/digital-store-client/src/containers/Checkout/common/addDiscountModal'
import _ from 'lodash'

class ctApiObjectMapper {
  mapCTBasket = async ({ basketProducts, ctBasket }) => {
    const currentOrderProducts = ctBasket.items.map((orderItem) => this.mapCTProduct({ orderItem, basketProducts, forCheckout: false })).flat()
    const currentOrderSamples = ctBasket.samples.map((orderItem) => this.mapCTProduct({ orderItem, basketProducts, forCheckout: false })).flat()
    const products = ctBasket.items.map((orderItem) => this.mapCTProduct({ orderItem, basketProducts, forCheckout: true })).flat()
    const samples = ctBasket.samples.map((orderItem) => this.mapCTProduct({ orderItem, basketProducts, forCheckout: true })).flat()
    const deliveryOption = this.mapDeliveryOption(ctBasket)

    const totalDiscount = {
      value: _.get(ctBasket, 'discount.value') || 0,
      code: _.get(ctBasket, 'discount.currencyCode') || _.get(ctBasket, 'total.currencyCode')
    }
    const ctDiscountValue = totalDiscount.value >= 0.01 ? totalDiscount.value : null

    const discountedProducts = await new Promise((resolve, reject) => {
      if (!ctDiscountValue) {
        resolve(products)
      }
      const discountHandler = calculateDiscount({
        products,
        success: (result) => {
          resolve(_.map(result.discountedProducts, ({ product, discount }) => {
            return {
              ...product,
              manualDiscount: discount
            }
          }))
        },
        currencyCode: ctBasket.total.currencyCode,
        orderDiscount: true
      })
      discountHandler({ type: 'AMOUNT_DISCOUNT', amount: ctDiscountValue })
    })

    const mergedProducts = products.concat(samples)
    const mergedCurrentdOrderProducts = currentOrderProducts.concat(currentOrderSamples)

    return {
      products: mergedProducts,
      currentOrderProducts: mergedCurrentdOrderProducts,
      subTotal: { value: ctBasket.subtotal.value, code: ctBasket.subtotal.currencyCode },
      total: { value: ctBasket.total.value, code: ctBasket.total.currencyCode },
      taxOverride: { value: ctBasket.taxTotal.value, code: ctBasket.taxTotal.currencyCode },
      taxTotal: { value: ctBasket.taxTotal.value, code: ctBasket.taxTotal.currencyCode },
      basketId: ctBasket.id,
      promoCode: ctBasket.vouchers.length > 0 ? ctBasket.vouchers[0] : null,
      totalDiscount: { value: ctBasket.discount.value, code: ctBasket.discount.currencyCode },
      deliveryOption
    }
  }

  mapDeliveryOption = ({ deliveryOption }) => {
    if (!deliveryOption) {
      return null
    } else {
      return {
        id: deliveryOption.id,
        name: deliveryOption.name,
        deliveryType: 'home',
        active: true,
        price: {
          value: deliveryOption.cost.value,
          code: deliveryOption.cost.currencyCode
        },
        details: {
          code: deliveryOption.code,
          description: deliveryOption.description
        }
      }
    }
  }

  mapCTProduct = ({ orderItem, basketProducts, forCheckout }) => {
    return this.mapProduct({ orderItem, basketProducts, forCheckout })
  }

  mapProduct = ({ orderItem, basketProducts, forCheckout }) => {
    try {
      // look up the CT product in the oldDSBasket
      const ctProduct = orderItem.product
      const oldDSProduct = basketProducts.find(oldDSProduct =>
        oldDSProduct.externalProductId === ctProduct.sku
      )
      // if the product exists, we'll use that product as a starting point
      // if it doesn't exist we'll create a new empty product
      // NOTE: The property 'isAutoAddon' indicated products automatically added
      // to the basket (often, as a result of a promotion). We use this property
      // to prevent re-adding these products in the basket when we sync with CT.
      const dsProduct = oldDSProduct
        ? { ...oldDSProduct }
        : { isAutoAddon: true }
      // map relevant fields with CT fields
      dsProduct.type = dsProduct.type || 'product'
      dsProduct.id = dsProduct.id || ctProduct.id
      dsProduct.brand = dsProduct.brand || 'Charlotte Tilbury'
      dsProduct.link = dsProduct.link || 'https://www.charlottetilbury.com'

      dsProduct.price = { value: orderItem.subTotal.value / orderItem.quantity, code: orderItem.subTotal.currencyCode }
      dsProduct.discount = { value: orderItem.discount.value / orderItem.quantity, code: orderItem.discount.currencyCode }
      dsProduct.nowPrice = { value: dsProduct.price.value - dsProduct.discount.value, code: orderItem.subTotal.currencyCode }
      dsProduct.images = dsProduct.images || _.map(ctProduct.images, ({ imageSrc }) => `https://${imageSrc}`)
      dsProduct.externalProductId = ctProduct.sku
      dsProduct.preview = dsProduct.preview || false
      dsProduct.service = dsProduct.service || false

      dsProduct.name = ctProduct.subtitle
      dsProduct.groupName = ctProduct.title
      dsProduct.variant = {
        ...dsProduct.variant,
        name: `${ctProduct.title}` + ctProduct.subtitle ? ` - ${ctProduct.subtitle}` : ''
      }
      dsProduct.ctProductType = orderItem.configuration.productType
      dsProduct.selected = dsProduct.selected || false
      dsProduct.details = dsProduct.details || {}
      dsProduct.quantity = orderItem.quantity

      dsProduct.promotions = orderItem.promotions

      const dsOrderItems = []
      // duplicate object to every quantity
      for (let i = 0; i < orderItem.quantity; i++) {
        dsOrderItems.push({ ...dsProduct, quantity: 1 })
      }

      return forCheckout ? dsProduct : dsOrderItems
    } catch (error) {
      throw new Error(`Unable to update basket item - ${orderItem.toString()}, ${error.message} `, error.message)
    }
  }
}

export default new ctApiObjectMapper()
