import _ from 'lodash'
import { Modules } from '@redant/retailos-ui'
import { mothershipProxyRequest } from './CustomCheckoutFunctions'

export class CustomVirtualConsultationFunctions extends Modules.VirtualConsultation.Implementation.VirtualConsultationFunctions {
  mapFullProductToConsultationItemCM = (fullProduct) => {
    return {
      itemId: Modules.VirtualConsultation.Implementation.VirtualConsultationFunctions.constructItemId(fullProduct.id, fullProduct.variant.id),
      externalProductId: _.get(fullProduct, 'externalProductId', null),
      externalVariantId: _.get(fullProduct, 'variant.externalVariantId', null),
      active: false,
      // brand: fullProduct.brand, // remove brand name for CT
      description: fullProduct.details.description,
      imageUrl: fullProduct.images[0],
      inBasket: false,
      purchased: false,
      quantity: fullProduct.quantity || 1,
      isAutoAddon: fullProduct.isAutoAddon, // these are automatically added products e.g: promotional items.
      inWishList: false,
      name: fullProduct.productGroup || fullProduct.name,
      price: {
        code: this.getPriceCode(fullProduct),
        value: this.getPriceValue(fullProduct)
      },
      discount: fullProduct.discount,
      summary: fullProduct.details.summary,
      type: 'PRODUCT',
      variant: {
        id: fullProduct.variant.id,
        name: fullProduct.name
      },
      category: {
        id: fullProduct.category && fullProduct.category.id,
        name: fullProduct.category ? fullProduct.category.name : 'Other'
      },
      appearances: fullProduct.appearances,
      tutorialSteps: fullProduct.tutorialSteps
    }
  }

  getPriceCode(fullProduct) {
    if (fullProduct.isAutoAddon) {
      return fullProduct.price.code
    } else {
      return fullProduct.nowPrice ? fullProduct.nowPrice.code : fullProduct.price.code
    }
  }

  getPriceValue(fullProduct) {
    if (fullProduct.isAutoAddon) {
      return fullProduct.price.value
    } else {
      return fullProduct.nowPrice ? fullProduct.nowPrice.value : fullProduct.price.value
    }
  }

  async onPaymentSuccess(paymentIntent) {
    const {
      basketSessionToken,
      paymentIntentTokenPublic,
      retailOSOrderId,
      orderTotal,
      basketStoreCode
    } = paymentIntent
    await this.handlePayOrder({
      accessToken: basketSessionToken,
      paymentIntentToken: paymentIntentTokenPublic,
      orderId: retailOSOrderId,
      orderTotal,
      storeCode: basketStoreCode
    })
  }

  async handlePayOrder({
    orderId,
    orderTotal,
    accessToken,
    paymentIntentToken,
    storeCode
  }) {
    return mothershipProxyRequest({
      path: `/resources/${storeCode}/retailos/${orderId}/payment`,
      method: 'PUT',
      token: accessToken,
      body: {
        orderTotal,
        paymentIntentToken
      }
    })
  }
}
