/**
 * Main entrypoint file to
 * start the RetailOS React app.
 * This file is unlikely to need much editing.
 */

import { entrypoint, PlatformDependencies, setPasswordPolicy } from '@redant/digital-store-client'
import { initalizeCustomDependencies } from './customizations/dependencies'
import passwordPolicy from './passwordPolicy.config'

async function main () {
  try {
    // const res = await initalizeCustomDependencies()
    await PlatformDependencies.initializeDependencies()

    await entrypoint()

    // Run after the app starts to overwrite core platform default values
    require('./customizations/applyUiConfigMaps')
    // require('./customizations/translations/applyTranslations')
    setPasswordPolicy(passwordPolicy)
  
  } catch (error) {
    console.error('RETAILOS APPLICATION HAS CRASHED:')
    console.error(error)
  }
}

main()
