/**
 * This file is used to override UI configs for
 * "screens" in "@redant/retailos-ui"
 * See example below:
 */

import { setUiConfig } from '@redant/digital-store-client'

setUiConfig('Modules.Product.Screens.RootScreen', {
  sortOnly: true
})
