import { amendOrder } from '@redant/digital-store-prices-charlottetilbury/dist/handleOrders'
import { addPrices } from '@redant/digital-store-prices-charlottetilbury/dist/handlePrices'
import { Modules } from '@redant/retailos-ui'
import _ from 'lodash'
import uuid from 'uuid/v4'

import qs from 'qs'
import { createSelector } from 'reselect'
import currencyFormatter from '@redant/digital-store-client/src/formatters/currencyFormatter'
import modalService from '@redant/digital-store-client/src/services/modalService'
import ctApiObjectMapper from './ctApiObjectMapper'
import { useSelector } from 'react-redux'
import { getSelectedRegion, getSelectedRegionStripeKey } from '@redant/digital-store-client/src/store/modules/combinedSelectors/regionsCombinedSelectors'
import { PlatformDependencies } from '@redant/digital-store-client'
import { translations } from '@redant/digital-store-client/src/config'

export const getCTBasketAPIStoreCode = createSelector(
  getSelectedRegion,
  region => _.get(region, 'details.ctBasketAPIStoreCode')
)

export const mothershipProxyRequest = ({
  path,
  token,
  body,
  method
}) => {
  return PlatformDependencies.httpClientService.axios({
    method,
    url: `/v2/custom/mothership${path}`,
    data: body,
    headers: {
      ...token ? { 'Basket-Authorization': token } : {}
    },
  }).then(({ data }) => data)
}

export class CustomCheckoutFunctions extends Modules.Checkout.Implementation.CheckoutFunctions {
  constructor(httpClientService) {
    super(httpClientService)
    this.storeCode = null
    this.stripePublicKey = null
    this.basketSessionToken = null
    this.basketId = null
  }


  /**
   * Runs on CheckoutScreen mount. use to assign any checkout-specific context to `this`.
   */
  useCheckoutScreenMount() {
    const storeCode = useSelector(getCTBasketAPIStoreCode)
    const stripePublicKey = useSelector(getSelectedRegionStripeKey)
    
    this.storeCode = storeCode
    this.stripePublicKey = stripePublicKey
  }

  async getStoreDeliveryMethods() {
    // No store delivery for VCs
    return []
  }

  /**
   * The following methods with suffix CM are overrides of the base retailos-ui CheckoutFunctions class
   * They are responsible for calling async actions and returning state updates
   */

  async initialiseCheckoutCM(items, initialState) {
    const ctBasket = await this.syncCTBasket(items)
    const syncedState = await this.mapCTBasket(items, ctBasket.response)
    const deliveryMethods = await this.getDeliveryMethods(initialState)

    return {
      ...syncedState,
      ...initialState,
      deliveryMethods,
      deliveryMethod: deliveryMethods[0],
      uiState: 'REVIEW'
    }
  }

  async applyPromoCodeCM(promoCode, items) {
    const ctBasket = await this.addPromoCodeToCTBasket(promoCode)
    const syncedState = await this.mapCTBasket(items, ctBasket.response)
    return syncedState
  }

  async removePromoCodeCM(currentPromoCode, items) {
    const ctBasket = await this.removePromoCodeFromCTbasket(currentPromoCode.id)
    const syncedState = await this.mapCTBasket(items, ctBasket.response)
    return syncedState
  }

  async confirmReviewCM(state) {
    const ctBasket = await this.syncCTBasket(state.items.data, state.promoCode.data && state.promoCode.data.code)
    const syncedState = await this.mapCTBasket(state.items.data, ctBasket.response)

    return {
      ...syncedState,
      uiState: 'DELIVERY'
    }
  }

  async confirmDeliveryAddressCM(state, deliveryAddress) {
    const syncedState = await this.syncDeliveryAddressWithCT(state, deliveryAddress)
    return syncedState
  }

  async confirmDeliveryOptionCM(state, deliveryOption) {
    const syncedState = await this.syncDeliveryOptionWithCT(deliveryOption.details.code, state.items.data)
    return syncedState
  }

  async confirmOrderForPaymentCM(state, storeId) {
    const { id: orderId } = await this.uploadOrder(state, storeId)

    return {
      uiState: 'SELECT_PAYMENT_METHOD',
      orderId
    }
  }

  async submitPaymentIntentCM(state, storeId) {
    await this.addEmailToCTBasket(state.customer.email)

    const paymentToken = await this.fetchCTBasketPaymentIntent(state.paymentMethodOption)

    const [paymentIntentTokenPublic, paymentIntentTokenSecret] = paymentToken.split(':')

    await this.addPaymentIntentToROSOrder(state, paymentIntentTokenPublic, paymentIntentTokenSecret)

    return {
      payment: {
        completed: false,
        orderTotal: state.total,
        paymentIntent: {
          type: 'mothership',
          retailOSOrderId: state.orderId,
          basketStoreCode: this.storeCode,
          basketSessionToken: this.basketSessionToken,
          paymentIntentTokenPublic,
          paymentIntentTokenSecret,
          stripePublicKey: this.stripePublicKey
        },
        paymentMethod: state.paymentMethodOption
      },
      uiState: 'COMPLETE'
    }
  }

  mapOrderBreakdownCM(state) {
    if (!state.total || !state.subTotal) return []
    const breakdown = [
      {
        label: 'Subtotal',
        value: currencyFormatter.format(state.subTotal)
      },
      {
        label: 'Total discount',
        value: currencyFormatter.format(state.totalDiscount || { value: 0, code: state.total.code })
      }
    ]
    if (state.deliveryOption.data) {
      breakdown.push({
        label: state.deliveryOption.data.name,
        value: currencyFormatter.format(state.deliveryOption.data.price)
      })
    }
    if (this.storeCode === 'US') {
      breakdown.push({
        label: 'Tax',
        value: currencyFormatter.format(state.taxTotal || { value: 0, code: state.total.code })
      })
    }
    breakdown.push({
      label: 'Total',
      value: currencyFormatter.format(state.total),
      bold: true
    })
    return breakdown
  }

  /**
   * the products array of an order consists of single items of a given quantity
   * for example for a quantity of 5 of a single item, there will be 5 of that item present in the array
   * @param items
   * @returns
   */
  spreadItems(items) {
    const spreadItemArray = []
    for (const item of items) {
      let size = item.quantity
      while (size--) {
        spreadItemArray.push({
          ...item,
          quantity: 1
        })
      }
    }
    return spreadItemArray
  }

  async uploadOrder(state, storeId) {
    const orderProducts = this.spreadItems(state.items.data)
    const stateForAmendment = {
      ...state,
      products: orderProducts,
      deliveryOption: state.deliveryOption.data,
      deliveryAddress: state.deliveryAddress.data,
      deliveryType: state.deliveryOption.data && state.deliveryOption.data.deliveryType,
      tax: state.taxTotal,
      storeId,
      customerId: state.customer && state.customer.id
    }
    const amendedOrder = amendOrder(stateForAmendment)
    const products = _.chain(amendedOrder)
      .get('products', [])
      .map(product => {
        const variant = _.pick(product.variant, [
          'name',
          'ean',
          'details',
          'id'
        ])

        const orderProduct = {
          ..._.pick(product, [
            'brand',
            'categoryId',
            'discount',
            'externalProductId',
            'link',
            'manualDiscount',
            'name',
            'preview',
            'price',
            'subTotal',
            'total',
            'tax',
            'unsold',
            'clientId',
            'isLoved',
            'service',
            'refund',
            'hasBeenRefunded'
          ]),
          id: uuid(),
          productId: this.deconstructItemId(product.itemId).productId,
          variant: _.pickBy(variant, _.identity),
          images: [product.imageUrl]
        }
        return orderProduct
      })
      .value()
    const { data } = await this.httpClientService.post('/v2/orders', {
      updateSource: 'RetailOS',
      salesChannel: this.getSalesChannel(state),
      orderDate: new Date().toISOString(),
      status: this.getOrderStatus(state),
      orderType: this.getOrderType(state),
      tax: amendedOrder.taxTotal,
      total: addPrices([amendedOrder.total, amendedOrder.taxTotal]),
      ..._.pick(amendedOrder, [
        'customerId',
        'deliveryAddress',
        'deliveryDetails',
        'deliveryOption',
        'deliveryType',
        'id',
        'orderType',
        'paymentToken',
        'storeId',
        'subTotal',
        'totalDiscount',
        'userId'
      ]),
      details: {
        ..._.get(amendedOrder, 'details'),
        notes: state.orderNotes
      },
      products
    })
    return data.result
  }


  async addPaymentIntentToROSOrder(state, paymentIntentTokenPublic, paymentIntentTokenSecret) {
    const { data } = await this.httpClientService.patch(`v2/orders/${state.orderId}`, {
      updateSource: 'RetailOS',
      status: this.getStatusValue(state),
      details: {
        payment: {
          completed: false,
          orderTotal: state.total,
          paymentIntent: {
            type: 'mothership',
            retailOSOrderId: state.orderId,
            basketStoreCode: this.storeCode,
            basketSessionToken: this.basketSessionToken,
            paymentIntentTokenPublic,
            paymentIntentTokenSecret,
            stripePublicKey: this.stripePublicKey
          },
          paymentMethod: state.paymentMethodOption
        }
      }
    })
    return data
  }

  /**
   * The following custom methods are additions to the class to handle CT's specific checkout implementation
   */

  async syncCTBasket(items, promoCode) {
    await this.getBasketToken()
    if (promoCode && this.basketId) {
      this.addPromoCodeToCTBasket(promoCode)
    }
    const failedSyncProducts = await this.syncProductsWithCTBasket(items)
    if (failedSyncProducts.length > 0) {
      this.showOutOFStockError(failedSyncProducts)
    }
    const ctBasket = await this.getBasketFromCT()
    return ctBasket
  }

  async mapCTBasket(basketProducts, basketResponse) {
    const dsBasket = await ctApiObjectMapper.mapCTBasket({
      basketProducts,
      ctBasket: basketResponse
    })
    this.basketId = dsBasket.basketId
    const checkoutItems = dsBasket.currentOrderProducts.map(product => {
      const existingItem = basketProducts.find(item => item.itemId === product.itemId)
      if (existingItem) {
        return {
          ...existingItem,
          price: product.price,
          nowPrice: product.nowPrice,
          quantity: product.quantity
        }
      }
      return {
        ...this.mapAddOn(product),
        inBasket: true
      }
    })
    return {
      items: {
        data: checkoutItems
      },
      promoCode: {
        data: dsBasket.promoCode
      },
      deliveryOption: {
        data: dsBasket.deliveryOption
      },
      total: dsBasket.total,
      subTotal: dsBasket.subTotal,
      totalDiscount: dsBasket.totalDiscount,
      taxTotal: dsBasket.taxTotal,
      customAttributes: {
        taxOverride: dsBasket.taxOverride
      }
    }
  }

  mapAddOn(item) {
    return {
      ...item,
      imageUrl: item.images[0],
      itemId: this.constructItemId(item.id, item.variant.id),
      price: item.price,
      nowPrice: item.nowPrice
    }
  }

  async getBasketToken() {
    const mothershipResponse = await mothershipProxyRequest({
      path: `/resources/${this.storeCode}/token`,
      method: 'GET'
    })
    this.basketSessionToken = mothershipResponse.response.access_token
  }

  async getBasketFromCT() {
    const ctBasket = await mothershipProxyRequest({
      path: `/resources/${this.storeCode}/basket`,
      method: 'GET',
      token: this.basketSessionToken
    })
    return ctBasket
  }

  async syncProductsWithCTBasket(items) {
    const filteredItems = items.filter(item => !item.isAutoAddon)
    const failedSyncProducts = []
    for (const item of filteredItems) {
      try {
        await mothershipProxyRequest({
          path: `/resources/${this.storeCode}/basket/${item.externalProductId}`,
          method: 'POST',
          token: this.basketSessionToken,
          body: {
            quantity: item.quantity
          }
        })
      } catch (error) {
        failedSyncProducts.push(item)
      }
    }
    return failedSyncProducts
  }

  async syncDeliveryAddressWithCT(state, deliveryAddress) {
    const deliveryAddressId = state.customAttributes.deliveryAddressId
    const callMethod = deliveryAddressId ? 'PUT' : 'POST'
    const callPath =
      callMethod === 'POST'
        ? `/resources/${this.storeCode}/address`
        : `/resources/${this.storeCode}/address/${deliveryAddressId}`
    const { firstName, lastName, address1, address2, city, country, county, postCode } = deliveryAddress

    const addressResponse = await mothershipProxyRequest({
        path: `${callPath}`,
        method: `${callMethod}`,
        token: this.basketSessionToken,
        body: {
          firstName,
          lastName,
          streetLine1: address1,
          streetLine2: address2,
          city,
          county,
          postCode,
          country,
          defaultShipping: true,
          defaultBilling: true
        }
      })
    const addressId = addressResponse.response.id
    await mothershipProxyRequest({
      path: `/resources/${this.storeCode}/basket/address/shipping/${addressId}`,
      method: 'PUT',
      token: this.basketSessionToken
    })
    const deliveryResponse = await mothershipProxyRequest({
      path: `/resources/${this.storeCode}/basket/shippingmethod`,
      method: 'GET',
      token: this.basketSessionToken
    })
    const { options, selectedId } = deliveryResponse.response
    const filteredOptions = options.filter(option => option.code !== 'us-shoprunner')
    const deliveryOptions = filteredOptions.map((shippingOption) => {
      return {
        id: shippingOption.id,
        name: shippingOption.name,
        deliveryType: 'home',
        active: true,
        price: {
          value: shippingOption.cost.value,
          code: shippingOption.cost.currencyCode
        },
        details: {
          code: shippingOption.code,
          description: shippingOption.description
        }
      }
    })
    // Place pre-selected returned delivery option in first position
    const sortedDeliveryOptions = deliveryOptions.sort((a, b) => {
      return a.id === selectedId ? -1 : b.id === selectedId ? 1 : 0
    })
    const defaultDeliveryOption = deliveryOptions.find((option) => option.id === selectedId)
    const syncedState = await this.syncDeliveryOptionWithCT(defaultDeliveryOption.details.code, state.items.data)

    return {
      ...syncedState,
      deliveryOptions: sortedDeliveryOptions,
      deliveryOption: {
        data: defaultDeliveryOption
      },
      deliveryAddress: {
        data: deliveryAddress
      },
      customAttributes: {
        deliveryAddressId
      }
    }
  }

  async syncDeliveryOptionWithCT(shippingMethodCode, items) {
    const ctBasket = await mothershipProxyRequest({
        path: `/resources/${this.storeCode}/basket/shippingmethod/${shippingMethodCode}`,
        method: 'PUT',
        token: this.basketSessionToken
      })
    const newState = await this.mapCTBasket(items, ctBasket.response)
    return newState
  }

  async addPromoCodeToCTBasket(newPromoCode) {
    const mothershipResponse = await mothershipProxyRequest({
        path: `/resources/${this.storeCode}/basket/${this.basketId}/voucher/${newPromoCode}`,
        method: 'POST',
        token: this.basketSessionToken
      })
    return mothershipResponse
  }

  async addEmailToCTBasket(email) {
    mothershipProxyRequest({
      path: `/resources/${this.storeCode}/account/email`,
      method: 'PUT',
      token: this.basketSessionToken,
      body: {
        email,
        thirdParty: true
      }
    })
  }

  async removePromoCodeFromCTbasket(promoCodeId) {
    const mothershipResponse = await mothershipProxyRequest({
        path: `/resources/${this.storeCode}/basket/${this.basketId}/voucher/${promoCodeId}`,
        method: 'DELETE',
        token: this.basketSessionToken
      })
    return mothershipResponse
  }

  async fetchCTBasketPaymentIntent(paymentMethod) {
    const queryStringObj = {
      attribution: 'RED_ANT',
      paymentMethodType: paymentMethod.id === 'KLARNA' ? paymentMethod.id : undefined
    }

    const queryString = qs.stringify(_.omitBy(queryStringObj, _.isNil))

    const paymentResponse = await mothershipProxyRequest({
        path: `/resources/${this.storeCode}/paymentintent?${queryString}`,
        method: 'GET',
        token: this.basketSessionToken
      })
    return paymentResponse.response.paymentIntentToken
  }

  showOutOFStockError(failedSyncProducts) {
    modalService.close({ modalIndex: 0 })
    modalService.continue({
      modalIndex: 3,
      title: translations('Out Of Stock'),
      text: `${translations('Product Sync Fail Text')} \n\n${translations(
        'Please Remove From Bag'
      )} \n${failedSyncProducts.map(({ name }) => name).join('\n')}`,
      success: () => {
        modalService.close({ modalIndex: 3 })
        // TODO: set ui state to in progress
      }
    })
  }
}
