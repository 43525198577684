/**
 * JavaScript entrypoint to the application.
 */

import { setClientConfig } from '@redant/digital-store-client/src/retailos/clientConfig'
import retailosConfig from './config.json'

setClientConfig(retailosConfig)

require('./main')
