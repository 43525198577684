import { CustomProductCatalogueFunctions } from './CustomProductCatalogueFunctions'
import { CustomCheckoutFunctions } from './CustomCheckoutFunctions'
import { CustomVirtualConsultationFunctions } from './CustomVirtualConsultationFunctions'
import { PlatformDependencies } from '@redant/digital-store-client'
import { general } from '../../config.json'

const customProductCatalogueFunctions = new CustomProductCatalogueFunctions(PlatformDependencies.httpClientService)
const customCheckoutFunctions = new CustomCheckoutFunctions(PlatformDependencies.httpClientService, '', '')
const virtualConsultationFunctions = new CustomVirtualConsultationFunctions(PlatformDependencies.httpClientService)

async function initalizeCustomDependencies() {
  await customProductCatalogueFunctions.initialize({
    useProductGroups: general.groupedProductsEnabled
  })

  return {
    overrides: {
      productCatalogueFunctions: customProductCatalogueFunctions,
      virtualConsultationCheckoutFunctions: customCheckoutFunctions,
      virtualConsultationFunctions: virtualConsultationFunctions,
    }
  }
}
export {
  initalizeCustomDependencies
}
