const passwordPolicy = {
  REQUIRED: {
    rule: 6,
    message: 'Field is required'
  },
  // ONLY_NUMBERS: {
  //   rule: 1,
  //   message: 'Password must be numeric'
  // },
  // MIN_LENGTH: {
  //   rule: 8,
  //   message: 'Password must be at least 8 characters long'
  // },
  // MAX_LENGTH: {
  //   rule: 15,
  //   message: 'Password must be no more than 15 characters long'
  // },
  // EXACT_LENGTH: {
  //   rule: 6,
  //   message: 'Password must be exactly 6 numerical characters'
  // },
  // UPPERCASE_MIN_COUNT: {
  //   rule: 1,
  //   message: 'Password must include at least 1 uppercase letter'
  // },
  // LOWERCASE_MIN_COUNT: {
  //   rule: 1,
  //   message: 'Password must include at least 1 lowercase letter'
  // },
  // NUMBER_MIN_COUNT: {
  //   rule: 1,
  //   message: 'Password must include at least 1 number'
  // },
  // SPECIAL_CHAR_MIN_COUNT: {
  //   rule: 1,
  //   message: 'Password must at least 1 special character',
  // },
  // MIN_UNIQUE_CHAR_COUNT: {
  //   rule: 3,
  //   message: 'Password must have atleast 3 unique numbers'
  // },
  // NON_REPEATING: {
  //   rule: 4,
  //   message: 'A password cannot have more than 3 of the same digit in a row'
  // },
  // NON_REPEATING_INSTANCE: {
  //   rule: 3,
  //   message: 'Password cannot have same digit more than twice'
  // },
  // NON_CONSECUTIVE: {
  //   rule: 2,
  //   message: 'A password cannot have 3 or more consecutive digits'
  // }
}

export default passwordPolicy
